import React from 'react'
import Layout from '../components/Layout'
import { TitleCard, TitleSection } from '../components/common/Index.styled'
import Section from '../components/common/Section'
import SectionHeaderImage from '../components/common/SectionHeaderImage'

import image_background from '../images/privacy_background.jpg'

const Privacidad = props => (
  <Layout
    title="Aviso de privacidad"
    description="Conoce nuestro aviso de privacidad."
    {...props}
  >
    <SectionHeaderImage image={image_background} position="90% 0" />
    <Section>
      <TitleSection>Aviso de privacidad</TitleSection>
      <div
        style={{ textAlign: 'justify', maxWidth: '700px', margin: '0 auto' }}
      >
        <p>
          De acuerdo a lo previsto en la “Ley de Protección de Datos
          Personales”, declara <b>Arreguin Sánchez y Asociados S.C.</b>, ser una
          empresa legalmente constituida de conformidad con las leyes mexicanas,
          con domicilio en Avenida Obrero Mundial 644, Colonia Atenor Salas,
          Alcaldía Benito Juárez, C.P. 03010, Ciudad de México; ser la
          responsable del tratamiento, uso y protección de sus datos personales.
          Hace de su conocimiento que la información de nuestros clientes es
          tratada de forma estrictamente confidencial, por lo que al
          proporcionar sus datos personales como:
        </p>
        <ul>
          <li>Nombre y apellidos</li>
          <li>Razón social o nombre comercial</li>
          <li>Teléfono de Domicilio/oficina/celular</li>
          <li>Correo electrónico</li>
          <li>Redes Sociales</li>
          <li>Logotipo con o sin registro</li>
          <li>Domicilio</li>
          <li>Código Postal</li>
          <li>RFC</li>
          <li>Contraseña para ingresar al portal del SAT</li>
          <li>e.firma KEY</li>
          <li>e.firma CER</li>
          <li>Clave privada de la e.firma</li>
        </ul>
        <p>
          Serán utilizados única y exclusivamente para los fines de los
          servicios ofrecidos por Arreguin Sánchez y Asociados S.C., se
          entenderá que reconoce y acepta los términos establecidos en el
          presente Aviso de Privacidad, sin perjuicio de que posteriormente
          usted decidiera ejercer sus derechos de revocación u oposición.
        </p>
        <TitleCard>Finalidades de los Datos Personales recabados</TitleCard>
        <p>
          Los datos personales que recabamos de usted, los utilizaremos para las
          siguientes finalidades que son necesarias para el servicio que
          solicita:
        </p>
        <ul>
          <li>Para verificar y confirma su identidad.</li>
          <li>
            Identificarlo previo, durante y posterior a la prestación de los
            servicios brindados.
          </li>
          <li>
            Brindar el servicio de notificación relacionadas con el servicio
            contratado.
          </li>
          <li>
            Proveer los servicios, productos y/o beneficios que ofrece Arreguin
            Sánchez y Asociados S.C.
          </li>
          <li>Enviarles boletines de noticias de la firma</li>
          <li>
            Registrarlos en el portal de{' '}
            <a href="https://asnews.mx/" target="_blank" rel="noreferrer">
              https://asnews.mx/
            </a>
          </li>
          <li>
            Registrarlos en{' '}
            <a
              href="https://ascg.uberprototech.com"
              target="_blank"
              rel="noreferrer"
            >
              https://ascg.uberprototech.com
            </a>
          </li>
          <li>Compartir sus datos con nuestros colaboradores internos.</li>
          <li>
            Registrarlos en nuestra plataforma de{' '}
            <a href="https://asnews.mx/" target="_blank" rel="noreferrer">
              https://asiconnect.com.mx
            </a>
          </li>
          <li>
            Extraer los archivos XML correspondiente a los CFDI registrados en
            el portal del SAT
          </li>
          <li>
            Utilizar sus logotipos o marca registrada como referencia en nuestro
            Brochure corporativo, propuestas económicas o página web{'  '}
            <a href="https://ascg.mx/" target="_blank" rel="noreferrer">
              https://ascg.mx/
            </a>
            {'  '}
            <a href="https://asxpert.com.mx/" target="_blank" rel="noreferrer">
              https://asxpert.com.mx/
            </a>
          </li>
        </ul>
        <p>
          De manera adicional, utilizaremos su información personal para las
          siguientes finalidades que no son necesarias para el servicio
          solicitado, pero que nos permiten y facilitan brindarle una mejor
          atención:
        </p>
        <ul>
          <li>Ofrecimiento de productos, servicios y promociones</li>
          <li>Estudios de Mercadotecnia.</li>
          <li>Realizar análisis demográficos.</li>
          <li>Realizar estudios de Publicidad.</li>
          <li>Prospección Comercial.</li>
          <li>Informativos respecto de nuestros servicios.</li>
        </ul>
        <p>
          Así mismo, manifestamos la negativa al tratamiento de los datos
          personales para finalidades que no son necesarias para el servicio
          solicitado
        </p>
        <p>
          Usted deberá hacer uso del ejercicio de su Derecho de Oposición
          establecido en la sección 'Medios y Procedimiento para el ejercicio de
          los Derechos ARCO'
        </p>
        <p>
          Cuando obtengamos los datos personales de manera indirecta, usted
          contará con un plazo de cinco días hábiles para que, de ser el caso,
          manifieste su negativa para el tratamiento de los datos personales
          para finalidades secundarias. Si no lo hace, se entenderá que
          consiente en el tratamiento de los datos personales para finalidades
          secundarias, sin perjuicio de que posteriormente usted decidiera
          ejercer sus derechos de revocación u oposición del presente aviso de
          privacidad.
        </p>
        <TitleCard>
          Datos Personales recabados para su tratamiento y los medios de
          obtención.
        </TitleCard>
        <p>
          Para llevar a cabo las finalidades previamente descritas en el
          presente aviso de privacidad, sus datos personales son recabados de
          forma directa cuando; (I) Usted ingresa sus datos a través de nuestro
          portal en internet ubicado en{' '}
          <a
            href="https://ascg.uberprototech.com"
            target="_blank"
            rel="noreferrer"
          >
            https://ascg.uberprototech.com
          </a>
          . (II) Por medio de nuestros correos electrónicos. (III) Vía
          telefónica. Asimismo, Arreguin Sánchez y Asociados S.C. podrá obtener
          información a través de; (i) Fuentes de acceso público. (ii)
          Transferencias permitidas por la Ley Federal de Protección de Datos
          Personales en Posesión de los Particulares, su Reglamento,
          lineamientos y demás preceptos aplicables.
        </p>
        <p>
          Los Datos Personales que usted nos proporciona, serán tratados de
          manera lícita y únicamente para las finalidades descritas en el
          presente aviso de privacidad. Asimismo, para la protección de sus
          Datos Personales estableceremos las medidas de seguridad
          administrativas, técnicas y físicas que nos permitan proteger sus
          datos personales contra daño, pérdida, alteración, destrucción o un
          uso distinto al señalado en el presente aviso de privacidad.
        </p>
        <TitleCard>Datos Personales de Terceros</TitleCard>
        <p>
          En caso de que el cliente proporcione datos personales de terceros a
          Arreguin Sánchez y Asociados S.C., el cliente deberá contar
          previamente con el consentimiento expreso del titular de dichos datos
          personales, de igual forma deberá informar al tercero la ubicación del
          portal de internet en donde podrá encontrar y conocer el aviso de
          privacidad de Arreguin Sánchez y Asociados S.C.
        </p>
        <TitleCard>Datos Personales Sensibles</TitleCard>
        <p>
          Arreguin Sánchez y Asociados S.C., NO RECABA O RECIBE DATOS PERSONALES
          SENSIBLES, toda vez que no son necesarios para el cumplimiento de los
          servicios que proporcionamos.
        </p>
        <TitleCard>Transferencia de los Datos Personales</TitleCard>
        <p>
          Para el cumplimiento de las finalidades señaladas, del presente aviso
          de privacidad, usted autoriza a la empresa para transferir sus datos
          personales, en términos del artículo 37 de la Ley Federal de
          Protección de Datos Personales en Posesión de los Particulares, a
          empresas dentro y fuera de los Estados Unidos Mexicanos las cuales
          realizan las siguientes actividades:
        </p>
        <ol type="a">
          <li>
            Sociedades que forman parte de Arreguin Sánchez y Asociados S.C..
            (AS Consulting Group) con la finalidad de brindarle un mejor
            servicio. Estas sociedades establecerán las medidas de seguridad
            administrativas, técnicas y físicas que nos permitan proteger los
            datos personales contra daño, pérdida, alteración, destrucción o un
            uso distinto al señalado en el presente aviso de privacidad.
          </li>
        </ol>
        <TitleCard>
          Medios y Procedimiento para el ejercicio de los Derechos ARCO
        </TitleCard>
        <p>
          Los Derechos ARCO son el mecanismo legal que le permiten ejercer sus
          Derechos de Acceso, Rectificación, Cancelación y Oposición, los cuales
          consisten en:
        </p>

        <ul>
          <li>
            Acceder a los datos personales, exclusivamente a los suyos salvo que
            sea representante legal o tutor de otro titular, que se encuentran
            bajo nuestro tratamiento
          </li>
          <li>
            Solicitar que realicemos correcciones o actualizaciones de sus datos
            personales
          </li>
          <li>
            Solicitar que eliminemos sus datos personales, cuando esto sea
            legalmente procedente
          </li>
          <li>
            Oponerse a la realización de las finalidades primarias establecidas
            en este Aviso de Privacidad – supuesto bajo el cual no seguirán
            prestándose los servicios que ofrecemos
          </li>
          <li>
            Solicitar su oposición a la realización de las finalidades
            secundarias indicadas
          </li>
          <li>
            Solicitar que sus datos personales no sean transferidos a terceros
          </li>
          <li>
            Limitar el uso o bien revocar el consentimiento al tratamiento de
            sus datos personales.
          </li>
        </ul>
        <TitleCard>MEDIOS PARA EJERCER LOS DERECHOS ARCO</TitleCard>
        <p>
          Para el ejercicio de cualquiera de los derechos ARCO, usted deberá
          presentar la solicitud respectiva enviando un correo electrónico a:{' '}
          <a href="mailto:privacidad@uberprototech.com">
            privacidad@uberprototech.com
          </a>
          , con el asunto “Solicitud de derechos ARCO”.
        </p>
        <p>
          Para conocer el procedimiento y requisitos para el ejercicio de los
          derechos ARCO, usted podrá ingresar a nuestro sitio de Internet{' '}
          <a
            href="https://ascg.uberprototech.com"
            target="_blank"
            rel="noreferrer"
          >
            https://ascg.uberprototech.com
          </a>{' '}
          a la sección “privacidad”, o bien ponerse en contacto con nuestro
          Departamento de Privacidad, que dará trámite a las solicitudes para el
          ejercicio de estos derechos, y atenderá cualquier duda que pudiera
          tener respecto al tratamiento de su información al correo{' '}
          <a href="mailto:privacidad@uberprototech.com">
            privacidad@uberprototech.com
          </a>
          .
        </p>
        <p>Uso de cookies, web beacons u otras tecnologías similares.</p>
        <p>
          Las cookies son texto que son descargados automáticamente y
          almacenados en el disco duro del equipo de cómputo del usuario al
          navegar en una página de Internet específica, que permiten recordar al
          servidor de Internet algunos datos sobre este usuario, entre ellos,
          sus preferencias para la visualización de las páginas en ese servidor,
          nombre y contraseña. No se encuentra obligado a aceptar las cookies
          que nosotros le enviemos, además de que puede modificar su navegador
          para no aceptar cookies.
        </p>

        <p>
          Por su parte, las web beacons son imágenes insertadas en una página de
          Internet o correo electrónico, que puede ser utilizado para monitorear
          el comportamiento de un visitante, como almacenar información sobre la
          dirección IP del usuario, duración del tiempo de interacción en dicha
          página y el tipo de navegador utilizado, entre otros.
        </p>
        <p>
          Hacemos de su conocimiento que hacemos uso de tecnologías electrónicas
          (cookies), que nos permiten recabar datos personales de manera
          electrónica y simultánea al tiempo que usted hace contacto con
          nosotros a través de nuestra página de internet:{' '}
          <a
            href="https://ascg.uberprototech.com"
            target="_blank"
            rel="noreferrer"
          >
            https://ascg.uberprototech.com
          </a>
          , por lo cual los datos que recabamos a través de las cookies son las
          siguientes:
        </p>
        <ol>
          <li>Información de su tipo de explorador.</li>
          <li>
            Información del horario y tiempo que permaneció en nuestra página.
          </li>
          <li>Secciones consultadas en nuestra página.</li>
        </ol>
        <p>
          Podemos utilizar servicios de terceros para colocar cookies de
          publicidad y dichas cookies podrán ser utilizadas por esos terceros
          para colocar publicidad en sitios de terceros.
        </p>
        <p>
          Los datos recabados, no son necesarios para cumplir con las
          obligaciones primarias señaladas en el presente aviso de privacidad,
          sin embargo, nos son de suma importancia y utilidad para cumplir con
          las siguientes finalidades:
        </p>
        <ol>
          <li>Enviar publicidad relacionada a sus gustos y preferencias.</li>
          <li>
            Hacerle llegar información relacionada a nuestros descuentas
            promociones.
          </li>
          <li>
            Ofrecerle nuevos productos y servicios basados en sus preferencias.
          </li>
        </ol>
        <p>
          En todo momento usted puede deshabilitar el uso de las cookies
          realizando el siguiente procedimiento:
        </p>

        <p>
          Internet Explorer (en Windows), tal y como se indica en el portal de
          internet publicado en{' '}
          <a
            href="http://windows.microsoft.com/es-mx/windows-vista/block-or-allow-cookies"
            target="_blank"
            rel="noreferrer"
          >
            http://windows.microsoft.com/es-mx/windows-vista/block-or-allow-cookies
          </a>
        </p>

        <p>
          Firefox, tal y como se indica en el portal de internet publicado en{' '}
          <a
            href="https://support.mozilla.org/es/kb/impedir-que-los-sitios-web-guarden-sus-preferencia"
            target="_blank"
            rel="noreferrer"
          >
            https://support.mozilla.org/es/kb/impedir-que-los-sitios-web-guarden-sus-preferencia
          </a>
        </p>

        <p>
          Safari, tal y como se indica en el portal de internet publicado en{' '}
          <a
            href="http://support.apple.com/kb/ht1677?viewlocale=es_ES"
            target="_blank"
            rel="noreferrer"
          >
            http://support.apple.com/kb/ht1677?viewlocale=es_ESa
          </a>
        </p>
        <p>Links a otros sitios.</p>
        <p>
          ASiViewer contiene links a otros sitios. Arreguin Sánchez y Asociados
          S.C. se deslinda en este acto de cualquier responsabilidad presente o
          futura de las prácticas de privacidad de dichos sitios.
        </p>

        <p>
          Este aviso de privacidad podrá ser modificado por Arreguin Sánchez y
          Asociados S.C., dichas modificaciones serán oportunamente informadas a
          través de correo electrónico, teléfono, o cualquier otro medio de
          comunicación que Arreguin Sánchez y Asociados S.C. determine para tal
          efecto.
        </p>

        <p>
          Somos AS Consulting Group, miembro de SMS Latinoamérica, firma
          especializada en servicios de contabilidad, asesoramiento fiscal,
          sector financiero, legal, laboral, inversión extranjera y consultoría
          para PyMEs, nacionales y extranjeras en México desde 1991, lo que
          garantizan la tranquilidad y el crecimiento de su negocio. Al ser
          miembros de SMS Latinoamérica nos permite tener presencia en más de 21
          países y ser parte del Foro de Firmas, Comité de IFAC (International
          Federation of Accountants).
        </p>
        <p>
          SMS Latinoamérica es una red de Firmas profesionales cuyos miembros
          son entidades legales separadas e independientes operando bajo su
          nombre particular e identificándose como miembros de SMS
          Latinoamérica. Cada una de las Firmas miembro actúa en un área
          geográfica específica y brinda servicios profesionales sujetos a las
          leyes y regulaciones profesionales del país o países en los que actúa.
          SMS Latinoamérica no brinda servicios a clientes y no es responsable
          por los actos u omisiones de cualquiera de sus firmas miembro. Las
          Firmas miembro son entidades legales separadas e independientes sin
          vinculación o intervención alguna sobre las demás Firmas miembro.
        </p>
        <p>
          Esta publicación sólo contiene información general de carácter
          informativo y ni AS Consulting Group , Arreguin Sánchez y Asociados,
          SMS Latinoamérica , ni sus firmas miembro, ni ninguna de sus
          respectivas afiliadas, presta asesoría o servicios por medio de esta
          publicación. Antes de tomar cualquier decisión o medida que pueda
          afectar sus finanzas o negocio, debe consultar a un asesor profesional
          calificado. Ninguna entidad, será responsable de pérdidas que pudiera
          sufrir cualquier persona o entidad que consulte esta publicación.
        </p>
        {/* <FormattedHTMLMessage id="notice_of_privacy.part1" />
        <p>
          <FormattedHTMLMessage id="notice_of_privacy.part2.description" />
        </p>
        <ul>
          {_.times(9, Number).map(value => (
            <FormattedHTMLMessage
              key={value}
              id={`notice_of_privacy.part2.list.item${value + 1}`}
            />
          ))}
        </ul>
        <FormattedHTMLMessage id="notice_of_privacy.part3" /> */}
      </div>
    </Section>
  </Layout>
)

export default Privacidad
